import './App.css';
import React, { useRef, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
/*import FormGroup from '@material-ui/core/FormGroup';*/
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ChippedMultiselect from './ChippedMultiselect';
import GFTypeSelect from './GFTypeSelect';
import GFSelect from './GFSelect';
import PropTypes from 'prop-types';

import useStyles from './styles';
import tbFormConfig from './tbFormConfig';

export const SENDING_NONE = "none";
export const SENDING_IN_PROGRESS = "sending";
export const SENDING_FAILED = "failed";
export const SENDING_SUCCEEDED = "succeeded";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * If we'll need some more validation, it may make sense to move to Formik or Formik+Yup
 *
 * @param emailAddress can be null or empty
 */
function validateEmailAddress(emailAddress) {
    if (!emailAddress) {
        return 'Required';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress)
    ) {
        return 'Invalid email address';
    }
    return null;
}

function GFApplicationForm(props) {


    const locationRef = useRef();


    const [locationWarning, setLocationWarning] = useState(false);
    const [resumeWarning, setResumeWarning] = useState(false);

	function specialitiesForCategory(category) {
	  const categoryEntry = props.skillTree.find(item => Object.keys(item)[0] === category);
    const rawSpecList = (categoryEntry && categoryEntry[category].specialities) || [];
    const uniqSpecList = [...new Set(rawSpecList)];
	  return uniqSpecList.sort();
	}

	function skillsForCategory(category) {
	  const categoryEntry = props.skillTree.find(item => Object.keys(item)[0] === category);
	  if(!categoryEntry) return [];

	  const rawSkillList = categoryEntry[category].skills;
    const uniqSkillList = [...new Set(rawSkillList)];
    return uniqSkillList.sort();
	}

  // Go over array of category entries and for each entry get the very first key (as there is one only always)
  const categories = props.skillTree.map((value, key) => Object.keys(value)[0])

  const countriesList = [...new Set(props.countries.map((value, key) => Object.values(value)[0]))];

  const seniorityLevels = props.employmentOptions.seniorityLevels;

  const classes = useStyles();

  const employmentOptionsPossible = props.employmentOptions.employmentOptions;
  const workPreferencesPossible = props.employmentOptions.workPreferences;

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    emailAddress: "",
    // default non empty value makes sure field title pops out of a button to make it look nicer
    category: " ",
    artAndAnimationStyles: [],
    specialities: [],
    skills: [],
    gameGenres: [],
    gameEngines: [],
    gamePlatforms: [],
    experienceYears: null,
    gamesIndustryYears: null,
    // workExperience: workExperiencePossible[1],
    // default non empty value makes sure field title pops out of a button to make it look nicer
    seniorityLevel: " ",
    salaryExpectations: null,
    cvFile: null,
    linkedInLink: "",
    gitHubLink: "",
    jobTitle: "",
    location: "",
    employmentOptions: [],
    workPreferences: [],
    additionalInfo: "",
    country: "",
    city: "",
    readyToRelocate: false,
    agreeToJoinBoard: true,
    motivation: "",
    expectations: "",
    tasks: "",
    dreamJob: "",
    gameTitles: "",
    achievements: "",
    addCandidate: false
  });

  const updateField = e => {
    // console.log('updateField', e.target.name, e.target.value, typeof e.target.value)
    let value = e.target.value;
    if(e.target.type === "checkbox") {
      value = e.target.checked && e.target.value;
    } else if ((e.target.files && (e.target.files.length > 0))) {
        if((e.target.files[0].size  / 1024 / 1024) < tbFormConfig.maxCVFileSizeMB){
          value = e.target.files[0];
            setResumeWarning(false);
      } else {

            setResumeWarning(true);
        }
    }

    doUpdateField(e.target.name, value);
    if(e.target.name === "emailAddress") {
      validateEmailAddressFieldValue(value)
    }
  };

  const updateCategoryField = e => {
    // cleaning out the specialities field when category is changed
    doUpdateFields([{"category": e.target.value}, {"specialities": []}, {"skills": []}]);
  }

  // Cannot be called several times in the update cycle as the next one will override the previous one
  const doUpdateFields = nameValuePairs => {
    let updateObject = {...formValues};
    for (let entry of nameValuePairs) {
      const name = Object.keys(entry)[0];
      const value = entry[name];
      updateObject[name] = value;
    }
    // console.log('updateObject:', updateObject);
    setFormValues(updateObject);
  };

  // Cannot be called several times in the update cycle as the next one will override the previous one
  const doUpdateField = (fieldName, fieldValue) => {
    setFormValues({
      ...formValues,
      [fieldName]: fieldValue
    });
  }

 /**
 * "Known" as in we we are ready to display them
 * @type {{}}
 */
  const [knownErrors, setKnownErrors] = useState({
      emailAddress: null
  });

  function validateEmailAddressFieldValue(emailAddressFieldValue) {
      const emailAddressError = validateEmailAddress(emailAddressFieldValue);
      setKnownErrors({
          emailAddress: emailAddressError
      });
  }

  function handleSubmit(e) {
    e.preventDefault()

    let locEnabled = locationRef.current.querySelector("input").value !== '' && countriesList.includes(locationRef.current.querySelector("input").value);
    if(locEnabled){
      setLocationWarning(false);
    }
    if(knownErrors.emailAddress !== null) {
        console.log("Cannot submit form with invalid email address");
        return false;
    }

    if(locEnabled){
        if(props.onSubmitWish) {
          props.onSubmitWish({
            ...formValues
          });
        }
    } else {
        if(!locEnabled){
          setLocationWarning(true);
          window.scrollTo(0, locationRef.current.offsetTop) ;
        }
    }
  }

  function specialitiesChanged(e, value, reason) {
    // console.log('specialitiesChanged:', e, value, reason);
    if(Array.isArray(value) && value.length > 5) {
      value = value.slice(0, 5);
    }
    doUpdateFields([{"specialities": value}]);
  }

  function skillsChanged(e, value, reason) {
    doUpdateField('skills', value);
  }

  function gameGenresChanged(e, value, reason) {
    doUpdateField('gameGenres', value);
  }

  function gameEnginesChanged(e, value, reason) {
    doUpdateField('gameEngines', value);
  }

  function gamePlatformsChanged(e, value, reason) {
    doUpdateField('gamePlatforms',value);
}

  function artAndAnimationStylesChanged(e, value, reason) {
    doUpdateField('artAndAnimationStyles', value);
  }

  function countryChanged(e, value, reason) {
      doUpdateField('country', value);
  }

  function fileSelectionButtonLabel() {
    return formValues.cvFile ? formValues.cvFile.name : `Resume`;
  }

  function employmentOptionChanged(e, value) {
    doUpdateField("employmentOptions", value);
  }

  function workPreferencesChanged(e, value) {
    doUpdateField("workPreferences", value);
  }

  const fileControlLabelClassName = formValues.cvFile ?
  			classes.fileInputControlLabelFileSelected :
  			classes.fileInputControlLabelFileNonSelected;

  const showAndAndAnimationStyles = formValues.category === "Art & Animation";



  return (

        <form name="personregistration" onSubmit={handleSubmit}>
          <Grid className={classes.registrationForm}
            container
            spacing={4}
            direction="column"
            alignItems="stretch"
          >
              <Grid item className={classes.introText}>
              <h2>GameDev Talents Board application form</h2>
Fill in the registration form to make your anonymous profile visible to the recruiting Games studios from Finland and Nordics.
              </Grid>
                <Grid item xs={12}>
                  <FormControl required className={classes.formLine}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >First Name</InputLabel>
                      <TextField
                          required
                          className={classes.textFieldInput}
                          variant="filled"
                          name="firstName"
                          value={formValues.firstName}
                          onChange={updateField}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                  <FormControl required className={classes.formLine}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Last Name</InputLabel>
                      <TextField
                          required
                          className={classes.textFieldInput}
                          variant="filled"
                          name="lastName"
                          value={formValues.lastName}
                          onChange={updateField}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                  <FormControl required className={classes.formLine}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Email address</InputLabel>
                      <TextField
                          required
                          type="email"
                          className={classes.textFieldInput}
                          variant="filled"
                          name="emailAddress"
                          error={knownErrors.emailAddress}
                          helperText={knownErrors.emailAddress}
                          // onBlur={onNeedToValidateEmailAddress}
                          value={formValues.emailAddress}
                          onChange={updateField}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                  <FormControl  className={classes.formLine}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Phone</InputLabel>
                      <TextField
                          className={classes.textFieldInput}
                          variant="filled"
                          name="phone"
                          type="tel"
                          value={formValues.phone}
                          onChange={updateField}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required className={classes.formLine}>
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{asterisk: classes.inputLabelAsterisk}}
                        shrink
                    >LinkedIn profile</InputLabel>
                    <TextField
                        required
                        type="url"
                        className={classes.textFieldInput}
                        variant="filled"
                        name="linkedInLink"
                        value={formValues.linkedInLink}
                        onChange={updateField}
                    />
                </FormControl>
                <FormHelperText>Please fill in a vazlid LI profile link</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formLine}>
                    <InputLabel
                        className={classes.inputLabel}
                        shrink
                    >Link to your GitHub, portfolio or web-site</InputLabel>
                    <TextField
                        type="url"
                        className={classes.textFieldInput}
                        variant="filled"
                        name="gitHubLink"
                        value={formValues.gitHubLink}
                        onChange={updateField}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required className={classes.formLine}>
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{asterisk: classes.inputLabelAsterisk}}
                        shrink
                    >Job Title</InputLabel>
                    <TextField
                        required
                        className={classes.textFieldInput}
                        variant="filled"
                        name="jobTitle"
                        value={formValues.jobTitle}
                        onChange={updateField}
                    />
                </FormControl>
                <FormHelperText>Please specify your current job title</FormHelperText>
              </Grid>

              <Grid
              item
              xs={12}
              >
              <Grid container direction="row" spacing={2}>
                  {/* <Grid item xs={4}>
                      <GFSelect
                        labelText="Work experience"
                        name="workExperience"
                        options={workExperiencePossible}
                        value={formValues.workExperience}
                        onChange={updateField}
                      />
                  </Grid> */}
                  <Grid item xs={4}>
                      <FormControl className={classes.nonLineFormElement}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Total Years of experience</InputLabel>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                            <TextField
                            className={classes.textFieldInput}
                            name="experienceYears"
                            type="number"
                            value={formValues.experienceYears}
                            onChange={updateField}
                            variant="filled"
                        />
                        </Grid>
                      </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl className={classes.nonLineFormElement}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Years of Experience in Games industry</InputLabel>
                      <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <TextField
                            className={classes.textFieldInput}
                            name="gamesIndustryYears"
                            type="number"
                            value={formValues.gamesIndustryYears}
                            onChange={updateField}
                            variant="filled"
                        />
                      </Grid>
                    </FormControl>
                      {/* <FormControl className={classes.nonLineFormElement}>
                      <InputLabel
                          className={classes.inputLabel}
                          classes={{asterisk: classes.inputLabelAsterisk}}
                          shrink
                      >Salary expectations</InputLabel>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                            <div>&euro;</div>
                            <TextField
                            className={classes.textFieldInput}
                            name="salaryExpectations"
                            type="number"
                            value={formValues.salaryExpectations}
                            onChange={updateField}
                            variant="filled"
                        />
                        </Grid>
                      </FormControl> */}
                  </Grid>
                  <Grid item xs={4}>
                      <FormControl className={classes.nonLineFormElement}>

                      <InputLabel
                            id="cvFileLabel"
                            className={classes.inputLabel}
                            classes={{asterisk: classes.inputLabelAsterisk}}
                            shrink
                        >Resume (Attachment)</InputLabel>
                        <Button
                          className={classes.fileInputControl}
                          variant="contained"
                          component="label"
                          startIcon={<AttachFileIcon style={{ fontSize: 14 }} /> }
                        >
                          <span className={fileControlLabelClassName}>{fileSelectionButtonLabel()}</span>
                          <input
                            type="file"
                            name="cvFile"
                            id="cvFileInput"
                            onChange={updateField}
                            hidden
                          />
                        </Button>
                        {resumeWarning && (
                          <Alert severity="error">Please select file less than 5GB!</Alert>
                        )}
                      </FormControl>

                      <FormHelperText><span>Please select file less than 5GB</span></FormHelperText>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formLine}>
                <InputLabel
                    className={classes.inputLabel}
                    classes={{asterisk: classes.inputLabelAsterisk}}
                    shrink
                >Salary expectations per month, &euro;</InputLabel>
                <TextField
                    className={classes.textFieldInput}
                    type="number"
                    variant="filled"
                    name="salaryExpectations"
                    value={formValues.salaryExpectations}
                    onChange={updateField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <GFSelect
                labelText="Seniority Level"
                name="seniorityLevel"
                options={seniorityLevels}
                value={formValues.seniorityLevel}
                onChange={updateField}
              />
              <FormHelperText>Please select from the dropdown list<br/><i>Your current seniority level, how you would consider your seniority</i></FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <GFSelect
                labelText="Category"
                name="category"
                options={categories}
                value={formValues.category}
                onChange={updateCategoryField}
              />
              <FormHelperText>Please select one category that you would like to continue working in</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <ChippedMultiselect
                name="specialities"
                labelText="Speciality"
                options={specialitiesForCategory(formValues.category)}
                onChange={specialitiesChanged}
                value={formValues.specialities}
              />
              <FormHelperText>Please specify max. 5 specialties within your category. If your specialty is not available in the list, enter it in the text field</FormHelperText>
            </Grid>
            <Grid item xs={12} hidden={!showAndAndAnimationStyles}>
              <ChippedMultiselect
                  name="artAndAnimationStyles"
                  labelText="Styles"
                  options={props.employmentOptions.artAndAnimationStyles}
                  onChange={artAndAnimationStylesChanged}
                  value={formValues.artAndAnimationStyles}
                  required={false}
              />
              <FormHelperText>Please list the art styles you have worked with. If your styles are not available in the list, enter them in the text field</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <ChippedMultiselect
                name="skills"
                labelText="Key Skills – Tech Stack, Technologies and Tools"
                options={skillsForCategory(formValues.category)}
                onChange={skillsChanged}
                value={formValues.skills}
              />
              <FormHelperText>Please list your tech skills and stack e.g. C++, Unity, Houdini etc. If your skills are not available in the list, enter it in the text field</FormHelperText>
            </Grid>

            {/*<Grid item xs={12}>
                <FormControl required className={classes.formLine}>
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{asterisk: classes.inputLabelAsterisk}}
                        shrink
                    >Location - Country</InputLabel>
                    <TextField
                        name="country"
                        required
                        className={classes.textFieldInput}
                        variant="filled"
                        value={formValues.country}
                        onChange={updateField}
                    />
                </FormControl>
            </Grid>*/}
            <Grid item xs={12}
                  ref={locationRef}>
                <GFTypeSelect
                  name="no"
                  labelText="Location - Country"
                  options={countriesList}
                  onChange={countryChanged}
                  value={formValues.country}
                  required={true}
                />
                {locationWarning && (
                  <Alert severity="error">Please fill correct country!</Alert>
                )}
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formLine}>
                    <InputLabel
                        className={classes.inputLabel}
                        shrink
                    >Location - City</InputLabel>
                    <TextField
                        name="city"
                        className={classes.textFieldInput}
                        variant="filled"
                        value={formValues.city}
                        onChange={updateField}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <ChippedMultiselect
                    name="employmentOptions"
                    labelText="Employment Options"
                    options={employmentOptionsPossible}
                    onChange={employmentOptionChanged}
                    value={formValues.employmentOptions}
                />
            </Grid>
            <Grid item xs={12}>
                <ChippedMultiselect
                    name="workPreferences"
                    labelText="Work Preferences"
                    options={workPreferencesPossible}
                    onChange={workPreferencesChanged}
                    value={formValues.workPreferences}
                />
            </Grid>
            <Grid item xs={12}>
              <ChippedMultiselect
                  name="gameGenres"
                  labelText="Game genres"
                  options={props.employmentOptions.gameGenres}
                  onChange={gameGenresChanged}
                  value={formValues.gameGenres}
                  required={false}
              />
              <FormHelperText>Please list game genres you have worked with. If your genres are not available in the list, enter them in the text field</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <ChippedMultiselect
                  name="gameEngines"
                  labelText="Game engines"
                  options={props.employmentOptions.gameEngines }
                  onChange={gameEnginesChanged}
                  value={formValues.gameEngines}
                  required={false}
              />
              <FormHelperText>Please list game engines you have worked with. If your engines are not available in the list, enter them in the text field</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <ChippedMultiselect
                  name="gamePlatforms"
                  labelText="Platforms"
                  options={props.employmentOptions.platforms}
                  onChange={gamePlatformsChanged}
                  value={formValues.gamePlatforms}
                  required={false}
              />
              <FormHelperText>Please list platforms you have worked with. If your platforms are not available in the list, enter them in the text field</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formLine}>
                <InputLabel
                    className={classes.inputLabel}
                    classes={{asterisk: classes.inputLabelAsterisk}}
                    shrink
                >Game Titles or Apps</InputLabel>
                <TextField
                    className={classes.textFieldInput}
                    variant="filled"
                    name="gameTitles"
                    value={formValues.gameTitles}
                    onChange={updateField}
                />
              </FormControl>
              <FormHelperText>Which game titles or applications were you working on?</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <FormControl className={classes.additionalInformationFormControl}>
                    <InputLabel
                        className={classes.employmentOptionsLabel}
                        shrink
                    >Tell us what motivates you</InputLabel>
                    <TextField
                        multiline
                        rows={4}
                        variant="filled"
                        name="motivation"
                        value={formValues.motivation}
                        onChange={updateField}
                    />
                    <div
                        className={classes.underInfo}
                    >Briefly mention your interests in what would you like to work on and where.</div>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.additionalInformationFormControl}>
                <InputLabel
                    className={classes.employmentOptionsLabel}
                    shrink
                >Tell us about you expectations of the Team and Company</InputLabel>
                <TextField
                    multiline
                    rows={4}
                    variant="filled"
                    name="expectations"
                    value={formValues.expectations}
                    onChange={updateField}
                />
                <div
                    className={classes.underInfo}
                >Briefly mention what is the ideal work environment you are looking for (in terms of company or team)</div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.additionalInformationFormControl}>
                <InputLabel
                    className={classes.employmentOptionsLabel}
                    shrink
                >What is it that you want to do - your key tasks</InputLabel>
                <TextField
                    multiline
                    rows={4}
                    variant="filled"
                    name="tasks"
                    value={formValues.tasks}
                    onChange={updateField}
                />
                <div
                    className={classes.underInfo}
                >Briefly mention the your ideal work day in terms of tasks</div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.additionalInformationFormControl}>
                <InputLabel
                    className={classes.employmentOptionsLabel}
                    shrink
                >What is your dream job?</InputLabel>
                <TextField
                    multiline
                    rows={4}
                    variant="filled"
                    name="dreamJob"
                    value={formValues.dreamJob}
                    onChange={updateField}
                />
                <div
                    className={classes.underInfo}
                >Briefly mention what you expect from your dream job.</div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.additionalInformationFormControl}>
                <InputLabel
                    className={classes.employmentOptionsLabel}
                    shrink
                >Achievements and things you are proud of</InputLabel>
                <TextField
                    multiline
                    rows={4}
                    variant="filled"
                    name="achievements"
                    value={formValues.achievements}
                    onChange={updateField}
                    inputProps={{ maxLength: 255 }}
                />
                <div
                    className={classes.underInfo}
                >What are the achievements you are most proud of?</div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required className={classes.additionalInformationFormControl}>
                <InputLabel
                  required
                  className={classes.employmentOptionsLabel}
                  classes={{asterisk: classes.inputLabelAsterisk}}
                  shrink
                >Are you willing to relocate?<br/>
                <i>If the right job opportunity comes - will you consider relocation to Finland?</i>
                </InputLabel>
                <RadioGroup required row name="readyToRelocate" value={formValues.readyToRelocate} onChange={updateField}>
                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                  </RadioGroup>
                {/*<FormControlLabel
                  className={classes.inputLabel}
                  shrink
                  control={<Checkbox checked={formValues.readyToRelocate} onChange={updateField} name="readyToRelocate" value="Yes"/>}
                  label="Ready to relocate"
                />*/}
              </FormControl>
              <Divider/>
            </Grid>
            {/*<Grid item xs={12}>
              <h3 className={classes.inFormBlockHeader}>Terms and Conditions</h3>
              <FormControl className={classes.additionalInformationFormControl}>
                <div className={classes.termsText}>We collect your data to connect you with the matching job opportunities and we use Zoho Recruit service to do so. We do not share your data with recruiting studios unless you agree.</div>
                <div className={classes.termsText}>Please familiarise yourself with our <a href="https://gamedevtalents.com/privacy-policy">Privacy Policy</a> and <a href="https://gamedevtalents.com/terms-of-service">Terms of Service</a></div>
                <InputLabel
                  required
                  className={classes.employmentOptionsLabel}
                  shrink
                >I accept Privacy Policy and Terms of Service</InputLabel>
                <FormControlLabel
                  required
                  className={classes.inputLabel}
                  shrink
                  control={<Checkbox required checked={formValues.termsAcceptance} onChange={updateField} name="termsAcceptance" value={true}/>}
                  label="I accept Privacy Policy and Terms of Service"
                />
                </FormControl>
            </Grid>*/}
            <Grid item xs={12}>
              <FormControl className={classes.additionalInformationFormControl}>
                <FormControlLabel
                  className={classes.inputLabel}
                  shrink
                  control={<Checkbox checked={formValues.addCandidate} onChange={updateField} name="addCandidate" value={true}/>}
                  label="Add candidate to talent board"
                />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              { props.sendingStatus === SENDING_FAILED &&
                <Alert className={classes.errorAlert} severity = "error">
                  We are sorry, something went wrong. Please, try again later or contact us by email.
                  {props.errorMessage && " Server tells: "} {props.errorMessage}
                </Alert>
              }
              { (!props.sendingStatus || props.sendingStatus === SENDING_NONE || props.sendingStatus === SENDING_FAILED ) &&
                <Button
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowRightAltIcon/>}
                    color="secondary">
                Register Now
                </Button>
              }
              { props.sendingStatus === SENDING_IN_PROGRESS &&
                <CircularProgress/>
              }
              {
                // For messages before posting such as e.g. file size limit
                props.sendingStatus === SENDING_NONE && props.errorMessage &&
                <Alert className={classes.errorAlert} severity = "error">{props.errorMessage}</Alert>
              }
              <Backdrop className={classes.backdrop} open={props.sendingStatus === SENDING_SUCCEEDED}>
                <Alert severity="success">
                  Thank you for your application. Please check your inbox (other folders) for next steps in joining our Talent Board.
                </Alert>
              </Backdrop>
            </Grid>
          </Grid>
        </form>
  );
}

GFApplicationForm.propTypes = {
  maxCVFileSizeMB: PropTypes.number,
  sendingStatus: PropTypes.oneOf([SENDING_NONE, SENDING_IN_PROGRESS, SENDING_FAILED, SENDING_SUCCEEDED]),
  errorMessage: PropTypes.string,
  onSubmitWish: PropTypes.func,
};

export default GFApplicationForm;
